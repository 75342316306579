/** @jsxImportSource @emotion/react */
import React from 'react';

type ServiceDetailTapberProps = {
	selectedTap: string;
	tapClicked: Function;
}

const ServiceDetailTapbar = (props: ServiceDetailTapberProps) => {
	const tapClicked = (type: string) => {
		props.tapClicked(type);
	};

	return (
		<>
			<div css={{
						display: 'inline-block',
						border: props.selectedTap === 'status' ? '1px solid #000000' : '',
						width: '180px',
						textAlign: 'center',
						lineHeight: '70px',
						fontFamily: 'Noto Sans, sans-serif',
						fontWeight: '500',
						fontSize: '20px',
						letterSpacing: '0.15px',
						color: '#000000',
						cursor: 'pointer',
					}}
					onClick={() => tapClicked('status')}
				>
					현황
				</div>
				<div css={{
						display: 'inline-block',
						border: props.selectedTap === 'maintenance' ? '1px solid #000000' : '',
						width: '180px',
						textAlign: 'center',
						lineHeight: '70px',
						fontFamily: 'Noto Sans, sans-serif',
						fontWeight: '500',
						fontSize: '20px',
						letterSpacing: '0.15px',
						color: '#000000',
						cursor: 'pointer',
					}}
					onClick={() => tapClicked('maintenance')}
				>
					점검관리
				</div>
				<div css={{
						display: 'inline-block',
						border: props.selectedTap === 'email' ? '1px solid #000000' : '',
						width: '180px',
						textAlign: 'center',
						lineHeight: '70px',
						fontFamily: 'Noto Sans, sans-serif',
						fontWeight: '500',
						fontSize: '20px',
						letterSpacing: '0.15px',
						color: '#000000',
						cursor: 'pointer',
					}}
					onClick={() => tapClicked('email')}
				>
					이메일 발송
				</div>
				<div css={{
						display: 'inline-block',
						border: props.selectedTap === 'popup' ? '1px solid #000000' : '',
						width: '180px',
						textAlign: 'center',
						lineHeight: '70px',
						fontFamily: 'Noto Sans, sans-serif',
						fontWeight: '500',
						fontSize: '20px',
						letterSpacing: '0.15px',
						color: '#000000',
						cursor: 'pointer',
					}}
					onClick={() => tapClicked('popup')}
				>
					팝업 게시
				</div>
			<div css={{
				display: 'inline-block',
				border: props.selectedTap === 'user' ? '1px solid #000000' : '',
				width: '180px',
				textAlign: 'center',
				lineHeight: '70px',
				fontFamily: 'Noto Sans, sans-serif',
				fontWeight: '500',
				fontSize: '20px',
				letterSpacing: '0.15px',
				color: '#000000',
				cursor: 'pointer',
			}}
					 onClick={() => tapClicked('user')}
			>
				고객 관리
			</div>
		</>
	);
};

export default ServiceDetailTapbar;