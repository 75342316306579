/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import CompanyListCombobox from '../../dashboard/CompanyListCombobox';

const UserEditModal = (props: any) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const httpClient = props.httpClient;
  const location = useLocation();
  const projectId = location.search.split('=')[1];
  const user = props.selectedUser;
  const [name, setName] = useState<string>(user.name);
  const [company, setCompany] = useState<string>(user.clientCompany);
  const [position, setPosition] = useState<string>(user.clientJobPosition);
  const [email, setEmail] = useState<string>(user.email);
  const [companyList, setCompanyList] = useState<string[]>([]);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    initVars();
  }, []);
  /*----------------------------------------
                 Business Logic
   ----------------------------------------*/
  const initVars = async () => {
    const companyData = await httpClient.getCompanyList(projectId, '');
    setCompanyList(companyData);
  };

  const save = () => {
    httpClient.putUserInfo(projectId, {
      id: user.id,
      name: name,
      clientCompany: company,
      clientJobPosition: position,
      email: email,
    }).then((result: number) => {
      if (result === 200) {
        props.setOpenEditModal(false);
        props.saved();
      }
    });
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div
      css={{
        backgroundColor: '#FFFFFF',
        width: '500px',
        height: '320px',
        padding: '20px',
        borderRadius: '16px',
      }}
    >
      <div>
        고객 정보 수정
      </div>
      <div css={{
        marginTop: '10px',
      }}>
        <TextField
          label="name"
          variant="standard"
          value={name}
          onChange={e => {setName(e.target.value)}}
        />
        <TextField
          label="position"
          variant="standard"
          sx={{ marginLeft: '20px' }}
          value={position}
          onChange={e => {setPosition(e.target.value)}}
        />
        <CompanyListCombobox width={195} defaultValue={company} companyList={companyList} onSelectionChanged={setCompany} />
        <TextField
          label="email"
          variant="standard"
          value={email}
          onChange={e => {setEmail(e.target.value)}}
        />
      </div>
      {/* button area */}
      <div
        css={{
          marginTop: '20px',
          overflow: 'auto',
        }}
      >
        <Button
          variant={'contained'}
          css={{
            float: 'right',
            marginLeft: '10px',
            width: '89px',
            height: '36px',
            borderRadius: '4px',
            backgroundColor: '#0071FF',
            fontFamily: 'Noto Sans, sans-serif',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '26px',
            letterSpacing: '0.46px',
            fontStyle: 'normal',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
          }}
          onClick={save}
        >
          저장
        </Button>
        <Button
          variant={'outlined'}
          css={{
            float: 'right',
            marginLeft: '10px',
            width: '89px',
            height: '36px',
            borderRadius: '4px',
            fontFamily: 'Noto Sans, sans-serif',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '26px',
            letterSpacing: '0.46px',
            fontStyle: 'normal',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#0071FF',
          }}
          onClick={() => props.setOpenEditModal(false)}
        >
          취소
        </Button>
      </div>
    </div>
  );
};

export default UserEditModal;