import { AWSAmplifyConfigure } from '@reconlabs/reconlabs-fe-components/dist_ts/components/templates/RDSAuthenticator';
export const awsconfig: AWSAmplifyConfigure = {
  Auth: {
    region: 'ap-northeast-2',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_HOSTING_DOMAIN as string,
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: process.env.REACT_APP_USER_POOL_SIGNIN_URL as string,
      redirectSignOut: process.env.REACT_APP_USER_POOL_SIGNOUT_URL as string,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};
