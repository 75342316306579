import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import HttpClient from './components/lib/http-client';
import Layout from './components/pages/Layout';
import Dashboard from './components/pages/dashboard/Dashboard';
import ServiceDetail from './components/pages/dashboard/ServiceDetail';
import Login from './components/pages/Login';
import { Authenticator} from '@aws-amplify/ui-react';
import Main from './components/pages/Main';
import {Amplify,Auth} from 'aws-amplify';
import { awsconfig } from './components/constants/awsConfig';


Amplify.configure(awsconfig);
Auth.configure(awsconfig)

const httpClient = new HttpClient();

const App = () => {
  return (
    <BrowserRouter>
      <Authenticator.Provider>
      <Routes>
        <Route path="/admin" element={<Layout httpClient={httpClient}></Layout>}>
          <Route path="/admin/dashboard" element={<Dashboard httpClient={httpClient}></Dashboard>}></Route>
          <Route path="/admin/dashboard/detail" element={<ServiceDetail httpClient={httpClient}></ServiceDetail>}></Route>
        </Route>
        <Route path="/signedin" element={<Main httpClient={httpClient}></Main>}></Route>
        <Route path="*" element={<Login httpClient={httpClient}></Login>}></Route>
        <Route path="/" element={<Login httpClient={httpClient}></Login>}></Route>
      </Routes>
      </Authenticator.Provider>
    </BrowserRouter>
  );
}

export default App;
