/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react';
import RDSDataTable from '../../../RDSDataTable';
import Chip from '@mui/material/Chip';
import ModalMaker from '../../../modals/ModalMaker';
import AddTagModal from './AddTagModal';
import UserEditModal from './UserEditModal';
import { UserInfo } from '../../../../types';
import { useLocation } from 'react-router-dom';

const CustomerManagement = (props: any) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const httpClient = props.httpClient;
  const location = useLocation();
  const projectId = location.search.split('=')[1];
  const [allCustomerCount, setAllCustomerCount] = useState<number>(0);
  const [tags, setTags] = useState<{value: string, count: number}[]>([]);
  const [openAddTagModal, setOpenAddTagModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [selectedUser, setSelectedUser] = useState<UserInfo>();
  const [columns, setColumns] = useState<any[]>([]);
  const [userList, setUserList] = useState<UserInfo[]>([]);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    setColumns([
      {
        name: '이름',
        selector: (row: any) => row.name,
        width: '180px',
        center: true,
        sortable: true,
      },
      {
        name: '회사',
        selector: (row: any) => row.clientCompany,
        width: '200px',
        center: true,
        sortable: true,
      },
      {
        name: '직책',
        selector: (row: any) => row.clientJobPosition,
        width: '200px',
        center: true,
        sortable: true,
      },
      {
        name: 'e-mail',
        selector: (row: any) => row.email,
        sortable: true,
        center: true,
        width: '200px',
      },
      {
        name: '태그',
        selector: (row: any) => row.tags,
        sortable: true,
        center: true,
        width: '200px',
        cell: (row: any) => 
          row && row.tags && row.tags.length > 0 && row.tags.split(',').map((tag: string, index: number) => {
            return (
              <Chip
                key={tag}
                label={`${tag}`}
                sx={{
                  marginLeft: index === 0 ? '' : '5px'
                }}
                variant="outlined"
              />
            );
          })
      },
      {
        name: '',
        width: '50px',
        cell: (row: any) => (
          <i className="fa fa-pencil" onClick={() => onEditButtonClicked(row)} />
        )
      },
      {
        name: '',
        width: '50px',
        cell: (row: any) => (
          <i className="fa fa-trash" onClick={() => onDeleteButtonClicked(row.id)} />
        )
      }
    ]);

    initVars();
  }, []);
  /*----------------------------------------
                 Business Logic
   ----------------------------------------*/
  const initVars = async () => {
    const data = await httpClient.getUserInfo(projectId);
    const userData = await httpClient.getEmailUserList(projectId);
    const count = data.count;
    const tags = data.tags;

    setAllCustomerCount(count);
    setTags(tags);
    setUserList(userData);
  };
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const onAddTagButtonClicked = (tags: string, id: number) => {
    if (tags && tags !== 'undefined' && tags !== 'null') {
      setSelectedTags(tags);
    } else {
      setSelectedTags('');
    }
    setSelectedUserId(id);
    setOpenAddTagModal(true);
  };

  const onEditButtonClicked = (row: any) => {
    const clientCompany = row.clientCompany;
    const clientJobPosition = row.clientJobPosition;
    const email = row.email;

    setSelectedUser({
      id: row.id,
      name: row.name,
      clientCompany: clientCompany && clientCompany !== 'undefined' && clientCompany !== 'null' ? clientCompany : '',
      clientJobPosition: clientJobPosition && clientJobPosition !== 'undefined' && clientJobPosition !== 'null' ? clientJobPosition : '',
      email: email,
    });
    setOpenEditModal(true);
  };

  const onDeleteButtonClicked = (id: number) => {
    httpClient.deleteUserInfo(projectId, id).then((result: number) => {
      if (result === 200) {
        refresh();
      }
    });
  };

  const handleClick = (value: string) => {
    console.log('value : ', value);
  };

  const refresh = async () => {
    const userData = await httpClient.getEmailUserList(projectId);
    setUserList(userData);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <ModalMaker state={openAddTagModal}>
        <AddTagModal
          tags={tags}
          userTableData={userList}
          selectedTags={selectedTags}
          selectedUserId={selectedUserId}
          setOpenAddTagModal={setOpenAddTagModal}
          saved={refresh}
          initVars={initVars}
          httpClient={httpClient} />
      </ModalMaker>
      <ModalMaker state={openEditModal}>
        <UserEditModal
          selectedUser={selectedUser}
          setOpenEditModal={setOpenEditModal}
          saved={refresh}
          httpClient={httpClient} />
      </ModalMaker>
      <div css={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', border: '1px solid #000000', padding: '15px' }}>
        <div>
          <div css={{
            fontFamily: 'Noto Sans, sans-serif',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '32px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.87)',
          }}>
            전체 고객 수 : {allCustomerCount}
          </div>
          <div>
            <div css={{
              marginTop: '24px',
              fontFamily: 'Noto Sans, sans-serif',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '13px',
              lineHeight: '18px',
              letterSpacing: '0.16px',
              color: 'rgba(0, 0, 0, 0.87)',
            }}>
              태그별 고객 보기
            </div>
            <div css={{
              marginTop: '24px'
            }}>
              {
                tags && tags.length > 0 ? (
                  tags.map((tag: {value: string, count: number}, index: number) => {
                    return <Chip key={tag.value} label={`${tag.value}(${tag.count})`} sx={{ marginLeft: index !== 0 ? '10px' : '' }} variant="outlined" onClick={() => handleClick(tag.value)} />
                  })
                ) : ''
              }
            </div>
          </div>
        </div>
        <div css={{
          width: '100%',
          height: '100%',
          marginTop: '24px',
          overflow: 'auto'
        }}>
          <RDSDataTable
            data={userList}
            columns={columns}
            searchInput={'default'}
            searchFilterKeyNames={['clientCompany', 'clientJobPosition','name', 'email', 'tags']}
            customStyle={{
              width: '100%',
              '& .TUEFO': {
                position: 'static',
                display: 'block',
                width: '300px',
                padding: '0',
              }
            }}
            actionButton={<></>}
            onChangePaginationOption={(e) => console.log(e)}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerManagement;