/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ModalMaker from '../../../modals/ModalMaker';

type PopupPreviewModalProps = {
  title: string;
  content: string;
  headerImageSrc: string;
  width: number;
  height: number;
  closePreviewModal: Function;
};

const PopupPreviewModal = ({ title, content, headerImageSrc, width, height, closePreviewModal }: PopupPreviewModalProps) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const [shouldModalOpen, setShouldModalOpen] = useState<boolean>(true);
  /*----------------------------------------
                 Business Logic
   ----------------------------------------*/
  const onClickNotToSeeToday = () => {
    closeModal();
  };

  const closeModal = () => {
    setShouldModalOpen(false);
    closePreviewModal(false);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <ModalMaker state={shouldModalOpen} closeModal={closeModal}>
      <div
        css={{
          backgroundColor: 'white',
          width: width,
          height: height,
        }}
      >
        <div css={{ padding: width / 20 }}>
          <div
            css={{
              justifyContent: 'right',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              marginBottom: '15px',
              fontWeight: '500',
            }}
          >
            오늘 다시보지 않을래요
            <div onClick={onClickNotToSeeToday} css={{ cursor: 'pointer' }}>
              <CloseIcon css={{ color: 'lightgrey' }} />
            </div>
          </div>
          <div
            css={{
              border: '#0071FF 1px solid',
              borderStyle: 'dashed',
              padding: `${height / 20}px ${width / 20}px ${height / 20}px ${width / 20}px`,
            }}
          >
            <div
              css={{
                height: '75px',
                textAlign: 'center',
                paddingBottom: '30px',
                '@media (max-width: 780px)': {
                  height: '40px',
                  paddingBottom: '10px',
                },
              }}
            >
              <div
                css={{
                  margin: ' 0 auto',
                  background: ' #0071FF',
                  width: '82px',
                  height: '82px',
                  '@media (max-width: 780px)': {
                    width: '45px',
                    height: '45px',
                  },
                }}
              >
                <img
                  css={{
                    marginTop: '21px',
                    width: '10px',
                    height: '40px',
                    '@media (max-width: 780px)': {
                      marginTop: '5px',
                      width: '10px',
                      height: '35px',
                    },
                  }}
                  src={headerImageSrc}
                />
              </div>
            </div>
            <div css={{ textAlign: 'center' }}>
              <div
                css={{
                  marginTop: '20px',
                  fontFamily: 'Noto Sans KR',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: '36px',
                  lineHeight: '52px',
                  textAlign: 'center',
                  whiteSpace: 'pre-line',
                  color: '#000000',
                  '@media (max-width: 780px)': {
                    fontSize: '23px',
                    lineHeight: '30px',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: title }}
              ></div>
              <div
                css={{
                  marginTop: '15px',
                  fontFamily: 'Noto Sans KR',
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '23px',
                  whiteSpace: 'pre-line',
                  '@media (max-width: 780px)': {
                    fontSize: '15px',
                    lineHeight: '18px',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
              <p />
              <div css={{ fontWeight: '800' }}>문의가 필요하시면 채널톡을 이용해주세요</div>
            </div>
          </div>
        </div>
      </div>
    </ModalMaker>
  );
};

export default PopupPreviewModal;
