import axios, { AxiosInstance } from 'axios';
import { PopupItem, ScheduledInspectionItem, PostEmailItem } from '../../types';
import * as dataConverter from './data-converter';
import { getEmailDetail, getEmailList, getCompanyList } from './data-converter';
import { Auth } from 'aws-amplify';

export type ReturnValue = {
  data: any;
};

export default class HttpClient {
  private httpRequest: AxiosInstance;
  requestRetriedCount: number;

  constructor() {
    this.requestRetriedCount = 0;
    this.httpRequest = axios.create({
      baseURL: `${process.env.REACT_APP_API_PATH}`,
      withCredentials: false,
    });
    this.httpRequest.interceptors.response.use(
      (res) => {
        console.log('request fullfiled (axios interceptor)');
        this.requestRetriedCount = 0;
        return res;
      },
      async (error) => {
        console.log(`retrying request ${this.requestRetriedCount} times`);
        if (this.requestRetriedCount === 4) {
          return;
        }
        this.requestRetriedCount++;
        const { url, params, method } = error.config;
        console.log(error.config);
        if (error.response.status === 401) {
          console.log('401 error, assume as id token staled and get another idtoken with refresh token');
          try {
            // Auth.currentSession 하면 refresh 자동 (호출 하지않아도 자동으로 되지만 끊겼다가 실행됨)
            const currentSession = await Auth.currentSession();
            const idToken = currentSession.getIdToken().getJwtToken()
            this.setAuthorization(idToken)
            console.log(url, method, params);
            alert('session이 만료되어 재요청중입니다 잠시만 기다려주세요')
            const res = await this.httpRequest({ method, url, params });
            console.log(res, this.httpRequest);
            return res;
            
          } catch (error: any) {
            console.log(error);
            console.log('failed to get token by refresh token');
            window.location.pathname = '/';
            throw Error('failed to get token');
          }
        }
      }
    );
    this.getAuthorization();
  }

  async getAuthorization() {
    const authorization = this.httpRequest.defaults.headers.common.Authorization;
    if (!authorization) {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      if (token) {
        this.setAuthorization(token);
      }
    }
  }

  setAuthorization(token: string) {
    console.log('setting header authro');
    this.httpRequest.defaults.headers.common = this.httpRequest.defaults.headers.common || {};
    this.httpRequest.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  async getLoginInfo(): Promise<ReturnValue> {
    const url = `${process.env.REACT_APP_API_PATH}/login`;
    const res = await this.httpRequest.get(url);
    return res.data.data;
  }

  async getProject(): Promise<ReturnValue> {
    const url = `${process.env.REACT_APP_API_PATH}/project`;
    const res = await this.httpRequest.get(url);
    return res.data;
  }

  async getInspectionList(
    projectId: string,
    searchWord: string | undefined
  ): Promise<{ historyData: ScheduledInspectionItem[]; expectedData: ScheduledInspectionItem[] }> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/schedule/list`;
    const res = await this.httpRequest.get(url, {
      params: {
        searchStr: searchWord,
      },
    });
    const data = res.data.data;
    return dataConverter.getInspectionList(data);
  }

  async putInspection(projectId: string, item: any): Promise<number> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/schedule`;
    const res = await this.httpRequest.put(url, item);
    return res.status;
  }

  async getPopupList(
    projectId: string,
    searchWord: string | undefined
  ): Promise<{ historyData: PopupItem[]; postingData: PopupItem[] }> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/popup/list`;
    const res = await this.httpRequest.get(url, {
      params: {
        searchStr: searchWord,
      },
    });
    const data = res.data.data;
    return dataConverter.getPopupList(data);
  }

  async putPopupList(projectId: string, item: any): Promise<number> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/popup`;
    const res = await this.httpRequest.put(url, item);
    return res.status;
  }

  async deletePopup(projectId: string, popupId: number): Promise<number> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/popup`;
    const res = await this.httpRequest.delete(url, {
      params: {
        popupId: popupId,
      },
    });
    return res.status;
  }

  async getEmailList(projectId: string, searchWord: string | undefined): Promise<PostEmailItem[]> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/email/list`;
    const res = await this.httpRequest.get(url, {
      params: {
        searchStr: searchWord,
      },
    });
    const data = res.data.data;
    return getEmailList(data);
  }

  async getEmailDetail(
    projectId: string,
    emailId: number
  ): Promise<{ title: string; typeValue: string; typeLabel: string; content: string }> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/email`;
    const res = await this.httpRequest.get(url, {
      params: {
        emailId: emailId,
      },
    });
    return getEmailDetail(res.data.data);
  }

  async postEmail(
    projectId: string,
    body: { type: string; title: string; content: string; userIdArr: number[] }
  ): Promise<number> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/email`;
    const res = await this.httpRequest.post(url, body);
    return res.status;
  }

  async getEmailUserList(projectId: string): Promise<any[]> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/email/userlist`;
    const res = await this.httpRequest.get(url);

    return res.data.data;
  }

  async getUserInfo(projectId: string): Promise<{ count: number; tags: { tag: string; count: number } }> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/user/all`;
    const res = await this.httpRequest.get(url, {
      params: {
        tag: true,
      },
    });
    const data = res.data.data;

    return { count: data.count, tags: data.tags };
  }

  async putUserInfo(
    projectId: string,
    userInfo: { id: number; name: string; clientCompany: string; clientJobPosition: string; email: string }
  ): Promise<number> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/user`;
    const res = await this.httpRequest.put(url, {
      id: userInfo.id,
      name: userInfo.name,
      clientCompany: userInfo.clientCompany,
      clientJobPosition: userInfo.clientJobPosition,
      email: userInfo.email,
    });

    return res.status;
  }

  async deleteUserInfo(projectId: string, userId: number): Promise<number> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/user`;
    const res = await this.httpRequest.delete(url, {
      params: {
        id: userId,
      },
    });

    return res.status;
  }

  async postUserTag(projectId: string, userId: string, tagArr: string[]): Promise<number> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/user/tag`;
    const res = await this.httpRequest.post(url, {
      userId: userId,
      tagArr: tagArr,
    });

    return res.status;
  }

  async deleteUserTag(projectId: string, userId: string, tag: string): Promise<number> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/user/tag`;
    const res = await this.httpRequest.delete(url, {
      params: {
        userId: userId,
        tag: tag,
      },
    });

    return res.status;
  }

  async postTag(projectId: string, tag: string): Promise<number> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/user/tag`;
    const res = await this.httpRequest.post(url, {
      tagArr: [tag],
    });

    return res.status;
  }

  async deleteTag(projectId: string, tag: string): Promise<number> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/user/tag`;
    const res = await this.httpRequest.delete(url, {
      params: {
        tag: tag,
      },
    });

    return res.status;
  }

  async getCompanyList(projectId: string, searchStr: string): Promise<string[]> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/company/list`;
    const res = await this.httpRequest.get(url, {
      params: {
        searchStr: searchStr,
      },
    });

    return getCompanyList(res.data);
  }
}
