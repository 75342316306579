/** @jsxImportSource @emotion/react */
import React from 'react';
import { ScheduledInspectionItem } from '../../../../types';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { parseTime } from '../../../lib/utils';

type ScheduledInspectionItems = {
	items: Array<ScheduledInspectionItem>;
	itemSelected: Function;
	selectedItem: ScheduledInspectionItem | undefined;
	inspectionCompleted: Function;
}

const ScheduledInspection = (props: ScheduledInspectionItems) => {
	/*----------------------------------------
                     Data
   ----------------------------------------*/
	const columns = [
		{name: '점검 예정 시각', width: '121px', prop: 'inspectionStartTime'},
		{name: '예상 소요 시간', width: '100px', prop: 'estimatedTime'},
		{name: '점검 유형', width: '100px', prop: 'typeLabel'},
		{name: '상태', width: '50px', prop: 'status'},
		{name: '수정', width: '50px', prop: 'edit'},
		{name: '점검 종료', width: '70px', prop: 'delete'},
	];
	/*----------------------------------------
                  Event Handler
   ----------------------------------------*/
	const onEditButtonClicked = (item: ScheduledInspectionItem) => {
		props.itemSelected(item);
	};

	const onDeleteButtonClicked = (item: ScheduledInspectionItem) => {
		// 점검을 아직 시작하지 않았다면 startTime 과 endTime 을 현재 시간으로 수정
		// 시작했다면 endTime 을 현재 시간으로 수정

		const currentDate = new Date();
		const startDate = new Date(item.inspectionStartTime.replaceAll('.', '-').replace(/ /g, 'T'));

		if (currentDate < startDate) {
			item.inspectionStartTime = parseTime(currentDate, '-', true, false);
			item.inspectionEndTime = parseTime(currentDate, '-', true, false);
		} else {
			item.inspectionEndTime = parseTime(currentDate, '-', true, false);
		}
		
		props.inspectionCompleted(item);
	};
	/*----------------------------------------
                Default Template
   ----------------------------------------*/
	return (
		<>
			<div
				css={{
					fontFamily: 'Noto Sans, sans-serif',
					fontStyle: 'normal',
					fontWeight: '500',
					fontSize: '20px',
					lineHeight: '32px',
					color: '#000000',
					letterSpacing: '0.15px',
					marginTop: '24px',
				}}
			>
				예정된 점검
			</div>
			<div
				css={{
					fontFamily: 'Noto Sans, sans-serif',
					fontStyle: 'normal',
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '22px',
					color: 'rgba(0, 0, 0, 0.87)',
					letterSpacing: '0.1px',
					marginTop: '24px',
				}}
			>

				{/* column header */}
				<div>
					{columns.map(item => {
						return (
							<div
								css={{
									display: 'inline-block',
									width: item.width,
									textAlign: 'center',
									marginRight: '24px',
								}}
								key={item.name}
							>
								{item.name}
							</div>
						);
					})}
				</div>

				{/* row items */}
				{props.items.map(item => {
					return (
						<div
							css={{
								marginTop: '15px',
								color: 'rgba(0, 0, 0, 0.6)',
								height: '24px',
								background: item === props.selectedItem ? '#65A9FF1A' : '',
							}}
							key={item.inspectionId}
						>
							{columns.map(column => {
								return (
									<div
										css={{
											display: 'inline-block',
											width: column.width,
											textAlign: 'center',
											marginRight: '24px',
											textDecorationLine: column.prop === 'typeLabel' || column.prop === 'status' ? 'underline' : '',
											color: column.prop === 'status' && item[column.prop] === '점검중' ? '#FF0071' : column.prop === 'status' && item[column.prop] === '대기중' ? '#00AA92' : '',
										}}
										key={`${item.inspectionId}+${column.prop}`}
									>
										{column.prop === 'edit' ?
											<EditIcon
												css={{ cursor: 'pointer', color: '#00000061' }}
												onClick={() => onEditButtonClicked(item)}
											/> :
											column.prop === 'delete' ?
												<DeleteIcon 
													css={{ cursor: 'pointer', color: '#00000061' }}
													onClick={() => onDeleteButtonClicked(item)}
												/> :
												item[column.prop]}
									</div>
								);
							})}
						</div>);
				})}
			</div>
		</>
	);
};

export default ScheduledInspection;