/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button } from '@mui/material';

type DeletePopupModalProps = {
  deletePopup: Function;
  deleteCancel: Function;
}

const DeletePopupModal = (props: DeletePopupModalProps) => {
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div
      css={{
        boxShadow:
          '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        width: '400px',
        height: '150px',
        backgroundColor: '#FFFFFF',
      }}
    >
      <div
        css={{
          margin: '20px',
        }}
      >
        <div
          css={{
            fontFamily: 'Noto Sans',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: '0.15px',
          }}
        >
          선택하신 팝업을 삭제하시겠습니까?
        </div>
        <div
          css={{
            marginTop: '30px',
            textAlign: 'center',
          }}
        >
          <Button
            variant={'contained'}
            css={{
              fontFamily: 'Noto Sans',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '24px',
              letterSpacing: '0.4px',
            }}
            onClick={() => props.deletePopup()}
          >
            삭제
          </Button>
          <Button
            variant={'outlined'}
            css={{
              marginLeft: '10px',
              fontFamily: 'Noto Sans',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '24px',
              letterSpacing: '0.4px',
            }}
            onClick={() => props.deleteCancel()}
          >
            취소
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeletePopupModal;