/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ServiceDetailTapbar from './ServiceDetailTapbar';
import ServiceStatus from '../tap/status/ServiceStatus';
import ServiceMaintenance from '../tap/matintenance/ServiceMaintenance';
import ServiceSendEmail from '../tap/email/ServiceSendEmail';
import ServicePostPopup from '../tap/popup/ServicePostPopup';
import CustomerManagement from '../tap/user/CustomerManagement';

const ServiceDetail = (props: any) => {
	/*----------------------------------------
                     Data
   ----------------------------------------*/
	const location = useLocation();
	const queryParams = location.search.split('?')[1];
	const serviceName = queryParams.split('=')[1];
	const [selectedTap, setSelectedTap] = useState<string>('status');

	const getContents = () => {
		let result;

		if (selectedTap === 'status') {
			result = <ServiceStatus />;
		} else if (selectedTap === 'maintenance') {
			result = <ServiceMaintenance httpClient={props.httpClient} />;
		} else if (selectedTap === 'email') {
			result = <ServiceSendEmail httpClient={props.httpClient} />;
		} else if (selectedTap === 'popup') {
			result = <ServicePostPopup httpClient={props.httpClient} />;
		} else if (selectedTap === 'user') {
			result = <CustomerManagement httpClient={props.httpClient} />;
		}
		
		return result;
	};
	/*----------------------------------------
                Default Template
   ----------------------------------------*/
	return (
		<div css={{ padding: '24px', height: '965px', background: '#ffffff !important' }}>
			<h4 css={{
					fontSize: '34px',
					fontWeight: '400',
					fontStyle: 'normal',
					fontFamily: 'Noto Sans, sans-serif',
					lineHeight: '123.5%',
					letterSpacing: '0.25px',
					color: 'rgba(0, 0, 0, 0.87)',
				}}
			>
				{serviceName}
			</h4>
			<div css={{
					marginTop: '38px',
					height: 'calc(100% - 80px)',
				}}
			>
				<ServiceDetailTapbar selectedTap={selectedTap} tapClicked={setSelectedTap} />
				<div css={{
						height: 'calc(100% - 70px)',
					}}
				>
					{getContents()}
				</div>
			</div>
		</div>
	);
};

export default ServiceDetail;