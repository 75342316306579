type zIndexes = {
  modalPrimaryContent: number;
  modalPrimaryBackground: number;
  modalSecondaryContent: number;
  modalSecondaryBackground: number;
  page5: number;
  page4: number;
  page3: number;
  page2: number;
  page1: number;
};

const zIndexes = {
  modalPrimaryContent: 30, // 페이지 전체에 영향을 끼치는 가장 우선시되는 모달의 컨텐츠
  modalPrimaryBackground: 25, // 페이지 전체에 영향을 끼치는 가장 우선시되는 모달의 배경
  modalSecondaryContent: 20, // 페이지 전체에 영향을 끼치는 가장 우선시되는 모달의 컨텐츠
  modalSecondaryBackground: 15, // 페이지 전체에 영향을 끼치는 가장 우선시되는 모달의 배경
  page5: 5, // 페이지 내부 컴포넌트 수준에서의 z-index
  page4: 4, // 페이지 내부 컴포넌트 수준에서의 z-index
  page3: 3, // 페이지 내부 컴포넌트 수준에서의 z-index
  page2: 2, // 페이지 내부 컴포넌트 수준에서의 z-index
  page1: 1, // 페이지 내부 컴포넌트 수준에서의 z-index
};

export { zIndexes };
