/** @jsxImportSource @emotion/react */
import React from 'react';
import ServiceCard from './ServiceCard';

const Dashboard = (props: any) => {
	/*----------------------------------------
                Default Template
   ----------------------------------------*/
	return (
		<div css={{ padding: '24px', height: '100%' }}>
			<h4 css={{
					fontSize: '34px',
					fontWeight: '400',
					fontStyle: 'normal',
					fontFamily: 'Noto Sans, sans-serif',
					lineHeight: '123.5%',
					letterSpacing: '0.25px',
					color: 'rgba(0, 0, 0, 0.87)',
				}}
			>
				서비스 현황
			</h4>
			<div css={{
					marginTop: '38px',
				}}
			>
				<ServiceCard marginRight={'21px'} serviceName={'plicar'} status={'점검중'} serviceInfo={'1000개'} mau={'123명'} />
			</div>
		</div>
	);
};

export default Dashboard;