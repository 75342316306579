import { PopupItem, PostEmailItem, ScheduledInspectionItem } from '../../types';

type TypeProps = {
  [index :string]: string;
  periodic: string;
  emergency: string;
  temp: string;
}

const getTypeLabel = (type: string) => {
  const types: TypeProps = {
    periodic: '정기 점검',
    emergency: '긴급 점검',
    temp: '임시 점검'
  };
  return types[type];
}

export const getInspectionList = (items: any) => {
  let historyData: ScheduledInspectionItem[] = [];
  let expectedData: ScheduledInspectionItem[] = [];
  items.forEach((item: any) => {
    const currentDate = new Date();
    const startDate = new Date(item.start_date.replace(/ /g, 'T'));
    const endDate = new Date(item.end_date.replace(/ /g, 'T'));
    const estimatedTime = Math.ceil((+endDate - (+startDate)) / (1000 * 60 * 60)).toString() + '시간';
    const status = currentDate < startDate ? '대기중' : currentDate > startDate && currentDate < endDate ? '점검중' : '점검 완료';
    const scheduledInspectionItem: ScheduledInspectionItem = {
      inspectionId: item.id.toString(),
      inspectionStartTime: item.start_date.substring(0, item.start_date.length - 3),
      inspectionEndTime: item.end_date.substring(0, item.start_date.length - 3),
      inspectionReason: item.description,
      estimatedTime: estimatedTime,
      typeValue: item.type,
      typeLabel: getTypeLabel(item.type),
      status: status
    }
    if (currentDate < endDate) {
      expectedData.push(scheduledInspectionItem)
    }
    historyData.push(scheduledInspectionItem);
  });

  return { historyData: historyData, expectedData: expectedData};
}

export const getEmailList = (items: any) => {
  let result: PostEmailItem[] = [];
  items.forEach((item: any) => {
    const emailItem: PostEmailItem = {
      emailId: item.id.toString(),
      postEmailTime: item.send_date,
      typeValue: item.type,
      typeLabel: getTypeLabel(item.type)
    }
    result.push(emailItem);
  });

  return result;
}

export const getEmailDetail = (item: { title: string, content: string, type: string }) => {
  return {
    title: item.title,
    content: item.content,
    typeValue: item.type,
    typeLabel: getTypeLabel(item.type)
  };
}

export const getPopupList = (items: any) => {
  let historyData: PopupItem[] = [];
  let postingData: PopupItem[] = [];
  items.forEach((item: any) => {
    const currentDate = new Date();
    const createDate = new Date(item.create_date.replaceAll('.', '-').replace(/ /g, 'T'));
    const endDate = new Date(item.end_date.replaceAll('.', '-').replace(/ /g, 'T'));
    const popupItem: PopupItem = {
      popupId: item.id.toString(),
      createDate: item.create_date.substring(0, item.create_date.length - 3),
      endDate: item.end_date.substring(0, item.create_date.length - 3),
      typeValue: item.type,
      typeLabel: getTypeLabel(item.type),
      state: item.state,
      status: currentDate > createDate && item.state === 'on' ? 'posting' : 'waiting',
      content: item.content,
      title: item.title,
    };
    historyData.push(popupItem);
    // 만료 되지 않은 팝업에 대해서 따로 저장
    if (currentDate < endDate) {
      postingData.push(popupItem);
    }
  })
  return { historyData: historyData, postingData: postingData };
}

export const getCompanyList = (items: {data : {name: string, industry: string | null}[], result: string}) => {
  const result: string[] = [];

  items.data.forEach((item: {name: string, industry: string | null}) => {
    if (item.name && item.name.length > 0) {
      result.push(item.name);
    }
  });

  return result;
}