/** @jsxImportSource @emotion/react */
// 최상단에 위의 것이 선언되어있어야 emotion 을 사용할 수 있습니다 (style대신 css)

import React from 'react';
import { zIndexes } from '../constants/zIndex';

type ModalMakerProps = {
  children: JSX.Element[] | JSX.Element | string;
  closeModal?: Function; // 검은색 배경화면을 눌렀을 때 화면 꺼지도록
  state: boolean; // 모달이 open 인지 close 인지 구분하기 위한 상태
};

/* 모달 사용법, 모달을 사용할 페이지 컴포넌트의 return 최상단에 아래와 같이 위치시킨다
  <ModalMaker state={true}>
    <PipelineAddManual />
  </ModalMaker>
*/
const ModalMaker = (props: ModalMakerProps) => {
  /*----------------------------------------
                Rendering Logic
   ----------------------------------------*/
  if (props.state === false) {
    return <></>;
  }
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div
      css={{
        display: props.state ? 'block' : 'none',
      }}
    >
      <div
        css={{
          position: 'absolute', // or fixed
          top: '50vh',
          left: 'clamp(720px, 50vw, 50vw)',
          transform: 'translateY(-50%) translateX(-50%)',
          zIndex: zIndexes.modalPrimaryContent,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={() => {}}
      >
        {props.children}
      </div>
      {/* 검은 배경 */}
      <div
        css={{
          boxSizing: 'border-box',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: zIndexes.modalPrimaryBackground,
        }}
        onClick={() => {
          if (props.closeModal) {
            props.closeModal();
          }
        }}
      ></div>
    </div>
  );
};

export default ModalMaker;
