/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import ServiceHistory from '../../dashboard/ServiceHistory';
import InspectionTypeSelectBox from '../../dashboard/InspectionTypeSelectBox';
import { PopupItem } from '../../../../types';
import { parseTime, stringToDate } from '../../../lib/utils';
import { Button } from '@mui/material';
import PostingPopup from './PostingPopup';
import TextField from '@mui/material/TextField';
import ModalMaker from '../../../modals/ModalMaker';
import DeletePopupModal from './DeletePopupModal';
import PopupPreviewModal from './PopupPreviewModal';
import { useLocation } from 'react-router-dom';

const ServicePostPopup = (props: any) => {
	/*----------------------------------------
                     Data
   ----------------------------------------*/
	const location = useLocation();
	const projectId = location.search.split('=')[1];
	const [historyItems, setHistoryItems] = useState<PopupItem[]>([]);
	const [postingItems, setPostingItems] = useState<PopupItem[]>([]);
	const [popupTitle, setPopupTitle] = useState<string>('');
	const [popupContent, setPopupContent] = useState<string>('');
	const [popupExpirationDate, setPopupExpirationDate] = useState<string>('');
	const [inspectionType, setInspectionType] = useState<{value: string, label: string}>({ value: 'emergency', label: '긴급 점검' });
	const [selectedItem, setSelectedItem] = useState<PopupItem | undefined>(undefined);
	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
	const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
	/*----------------------------------------
                   Life Cycle
   ----------------------------------------*/
	useEffect(() => {
		initVars(undefined);
	}, []);

	useEffect(() => {
		if (selectedItem) {
			setPopupTitle(selectedItem.title);
			setPopupExpirationDate(stringToDate(selectedItem.endDate));
			setInspectionType({value: selectedItem.typeValue, label: selectedItem.typeLabel});
			setPopupContent(selectedItem.content);
		}
	}, [selectedItem]);
	/*----------------------------------------
                 Business Logic
   ----------------------------------------*/
	const initVars = async (searchWord: string | undefined) => {
		const popupData = await props.httpClient.getPopupList(projectId, searchWord);
		const currentDate = new Date();
		setPopupExpirationDate(parseTime(currentDate, '-', true, false, false));
		setPopupTitle('');
		setPopupContent('');
		setInspectionType({ value: 'emergency', label: '긴급 점검' });
		setPostingItems(popupData.postingData);

		if (popupData.historyData.length > 16) {
			setHistoryItems(popupData.historyData.slice(0, 16));
		} else {
			setHistoryItems(popupData.historyData);
		}
	};

	const popupRegistration = () => {

		if (!validation()) {
			return;
		}

		props.httpClient.putPopupList(projectId, {
			popupId: selectedItem ? Number(selectedItem.popupId) : null,
			endDate: popupExpirationDate,
			type: inspectionType.value,
			state: selectedItem ? selectedItem.state : 'off',
			title: popupTitle,
			content: popupContent,
		}).then((result: number) => {
			if (result === 200) {
				setSelectedItem(undefined);
				initVars(undefined);
			} else {
				// 에러 발생
			}
		});
	};

	const validation = () => {
		let result = true;

		if (postingItems.length === 5) {
			console.log('팝업 현황은 5개를 초과할수 없습니다.');
			result = false;
		} else if (!popupTitle) {
			console.log('팝업 제목을 입력하세요.');
			result = false;
		} else if (!popupContent) {
			console.log('팝업 내용을 입력하세요.');
			result = false;
		} else if (stringToDate(parseTime(new Date(), '-', false, false)) > stringToDate(popupExpirationDate)) {
			console.log('팝업 만료 일자가 현재보다 이를 수 없습니다.');
			result = false;
		} else if (!popupExpirationDate) {
			console.log('팝업 만료 일자를 선택해 주세요.');
			result = false;
		}

		return result;
	};
	/*----------------------------------------
                  Event Handler
   ----------------------------------------*/
	const templateApply = (item: PopupItem) => {
		setSelectedItem(undefined);
		setPopupTitle(item.title);
		setPopupContent(item.content);
		setInspectionType({label: item.typeLabel, value: item.typeValue});
		setPopupExpirationDate(stringToDate(item.endDate));
	};

	const postConfirmed = (body: object) => {
		props.httpClient.putPopupList(projectId, body).then((result: number) => {
			if (result === 200) {
				initVars(undefined);
			}
		})
	};

	const deletePopup = () => {
		setOpenDeleteModal(false);
		props.httpClient.deletePopup(projectId, Number(selectedItem!.popupId)).then((result: number) => {
			if (result === 200) {
				initVars(undefined);
				setSelectedItem(undefined);
			}
		})
	};

	const deleteCancel = () => {
		setOpenDeleteModal(false);
		editCancel();
	};

	const editCancel = () => {
		setSelectedItem(undefined);
		initVars(undefined);
	};

	const closePreviewModal = (flag: boolean) => {
		setOpenPreviewModal(flag);
		editCancel();
	};

	const historySearch = (searchWord: string | undefined) => {
		initVars(searchWord);
	};
	/*----------------------------------------
                Default Template
   ----------------------------------------*/
	return (
		<>
			<ModalMaker state={openDeleteModal}>
				<DeletePopupModal deletePopup={deletePopup} deleteCancel={deleteCancel} />
			</ModalMaker>
			{openPreviewModal && (
				<PopupPreviewModal
					title={selectedItem ? selectedItem.title : ''}
					content={selectedItem? selectedItem.content : ''}
					headerImageSrc={`https://${process.env.REACT_APP_ASSET_DOMAIN}/icons/bang.png`}
					width={800}
					height={680}
					closePreviewModal={closePreviewModal}
				/>
			)}
			<div css={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
				<div css={{ width: '846px', height: '100%', border: '1px solid #000000', padding: '15px' }}>
					<div css={{
						height: '100%',
					}}
					>
						<PostingPopup
							postingItems={postingItems}
							postConfirmed={postConfirmed}
							itemSelected={setSelectedItem}
							selectedItem={selectedItem}
							setOpenDeleteModal={setOpenDeleteModal}
							setOpenPreviewModal={setOpenPreviewModal}
						/>
						<div
							css={{
								fontFamily: 'Noto Sans, sans-serif',
								fontStyle: 'normal',
								fontWeight: '500',
								fontSize: '20px',
								lineHeight: '32px',
								letterSpacing: '0.15px',
								color: '#000000',
							}}
						>
							팝업 등록
						</div>
						<div
							css={{
								marginTop: '24px',
							}}
						>
							<TextField
								sx={{
									width: '505px',
									height: '48px',
									fontFamily: 'Noto Sans, sans-serif',
									fontSize: '16px',
									fontWeight: '400',
									lineHeight: '48px',
									letterSpacing: '0.15px',
									textAlign: 'left',
								}}
								placeholder="제목을 입력해 주세요."
								size="medium"
								variant="standard"
								value={popupTitle}
								onChange={(e) => setPopupTitle(e.target.value)}
							/>
						</div>
						<div
							css={{
								marginTop: '24px',
								height: '40px',
								fontFamily: 'Noto Sans, sans-serif',
								fontStyle: 'normal',
								fontWeight: '400',
								fontSize: '16px',
								lineHeight: '40px',
								letterSpacing: '0.15px',
								color: '#000000',
							}}
						>
							팝업 만료 일자
							<input
								css={{
									marginLeft: '15px',
									width: '220px',
									height: '40px',
									textAlign: 'center',
									color: 'rgba(0, 0, 0, 0.6)',
									outline: 'none',
									border: '1px solid rgba(0, 0, 0, 0.23)',
									borderRadius: '4px',
									fontFamily: 'Noto Sans, sans-serif',
									fontSize: '16px',
									fontStyle: 'normal',
									fontWeight: '400',
									lineHeight: '24px',
									letterSpacing: '0.15px'
								}}
								value={popupExpirationDate}
								type="datetime-local"
								min="2022-01-01T00:00"
								max="2099-12-31T23:59"
								onChange={(e) => setPopupExpirationDate(e.target.value)}
							/>
						</div>
						<div
							css={{
								marginTop: '24px',
							}}
						>
							<InspectionTypeSelectBox inspectionType={inspectionType} setInspectionType={setInspectionType} />
						</div>
						<textarea
							css={{
								border: '1px solid rgba(0, 0, 0, 0.6)',
								borderRadius: '10px',
								marginTop: '24px',
								padding: '10px',
								height: '438px',
								width: '100%',
								outline: 'none',
								fontFamily: 'Noto Sans, sans-serif',
								fontSize: '16px',
								fontWeight: '400',
								lineHeight: '24px',
								letterSpacing: '0.15px',
								color: '#000000',
								resize: 'none',
							}}
							placeholder={'내용을 입력해 주세요.'}
							value={popupContent}
							onChange={(e) => setPopupContent(e.target.value)}
						/>
						<div css={{ marginTop: '24px', overflow: 'hidden' }}>
							<Button
								variant={'contained'}
								css={{
									float: 'right',
									width: '105px',
									height: '42px',
									borderRadius: '4px',
									backgroundColor: '#0071FF',
									fontFamily: 'Noto Sans, sans-serif',
									fontSize: '15px',
									fontWeight: '500',
									lineHeight: '26px',
									letterSpacing: '0.46px',
									fontStyle: 'normal',
									boxShadow: '0px 1px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.14)',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									color: '#FFFFFF',
								}}
								onClick={popupRegistration}
							>
								{selectedItem ? '팝업 수정' : '팝업 게시'}
							</Button>
							{selectedItem ? (
								<Button
									variant={'outlined'}
									css={{
										float: 'right',
										marginRight: '15px',
										width: '73px',
										height: '42px',
										borderColor: '#FF0071',
										borderRadius: '4px',
										fontFamily: 'Noto Sans, sans-serif',
										fontSize: '15px',
										fontWeight: '500',
										lineHeight: '26px',
										letterSpacing: '0.46px',
										fontStyle: 'normal',
										color: '#FF0071',
									}}
									onClick={editCancel}
								>
									취소
								</Button>
							) : ''}
						</div>
					</div>
				</div>
				<div css={{ width: '374px', height: '100%', marginLeft: '10px', border: '1px solid #000000' }}>
					<ServiceHistory type={'popup'} popupItems={historyItems} templateApply={templateApply} search={historySearch} />
				</div>
			</div>
		</>
	);
};

export default ServicePostPopup;