/** @jsxImportSource @emotion/react */
import React from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from 'react-router-dom';

type ServiceCardProps = {
	marginRight?: string;
	serviceName: string;
	status: string;
	serviceInfo?: string;
	mau?: string;
}

const ServiceCard = (props: ServiceCardProps) => {
	/*----------------------------------------
                     Data
   ----------------------------------------*/
	const navigate = useNavigate();
	/*----------------------------------------
                  Event Handler
   ----------------------------------------*/
	const openService = (serviceName: any) => {
		navigate(`/admin/dashboard/detail?serviceName=${serviceName}`);
	};
	/*----------------------------------------
                Default Template
   ----------------------------------------*/
	return (
		<div css={{
			border: '1px solid #000000',
			width: '396px',
			height: '206px',
			display: 'inline-block',
			marginRight: props.marginRight ? props.marginRight : '0',
		}}>
			<div css={{ padding: '15px' }}>
				<div css={{ 
						fontFamily: 'Noto Sans, sans-serif',
						fontSize: '24px',
						fontWeight: '400',
						fontStyle: 'normal',
						lineHeight: '32px',
						color: '#000000',
					}}
				>
					{props.serviceName}
					<span css={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => {openService(props.serviceName)}}>
						<LaunchIcon sx={{ color: '#00000068', width: '18px', height: '18px' }}></LaunchIcon>
					</span>
				</div>
				<div css={{
						marginTop: '24px',
						fontFamily: 'Noto Sans, sans-serif',
						fontSize: '16px',
						fontWeight: '400',
						fontStyle: 'normal',
						lineHeight: '24px',
						letterSpacing: '0.15px',
						color: '#000000',
					}}
				>
					<div css={{ display: 'flex', flexDirection: 'row' }}>
						<div css={{ flex: '1' }}>
							상태
						</div>
						<div css={{ flex: '1' }}>
							{props.status}
						</div>
					</div>
					<div css={{ marginTop: '24px', display: 'flex', flexDirection: 'row' }}>
						<div css={{ flex: '1' }}>
							서비스별 핵심정보
						</div>
						<div css={{ flex: '1' }}>
							{props.serviceInfo}
						</div>
					</div>
					<div css={{ marginTop: '24px', display: 'flex', flexDirection: 'row' }}>
						<div css={{ flex: '1' }}>
							MAU
						</div>
						<div css={{ flex: '1' }}>
							{props.mau}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceCard;