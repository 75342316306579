/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

const ServiceStatus = (props: any) => {
	/*----------------------------------------
                   Life Cycle
   ----------------------------------------*/
	useEffect(() => {
		document.querySelector('div.css-1taaqt9-ServiceDetail')?.setAttribute('style', 'height: 965px');
	}, []);
	/*----------------------------------------
                Default Template
   ----------------------------------------*/
	return (
		<div>
			TBD
		</div>
	);
};

export default ServiceStatus;