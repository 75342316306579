/** @jsxImportSource @emotion/react */
import { CSSObject } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { RowState } from 'react-data-table-component/dist/src/DataTable/types';

const parseKoreanTime = (date: Date | string | undefined | null, includeTime: boolean = true): string => {
  if (date === undefined || date === null) {
    return '';
  }
  const dateInstance = new Date(date);
  const YEAR = dateInstance.getFullYear(); // yyyy
  const MONTH = dateInstance.getMonth() + 1; // mm
  const DATE = dateInstance.getDate(); // dd
  const DAY = dateInstance.getDay();
  let DAY_NAME;
  switch (DAY) {
    case 0:
      DAY_NAME = '일';
      break;
    case 1:
      DAY_NAME = '월';
      break;
    case 2:
      DAY_NAME = '화';
      break;
    case 3:
      DAY_NAME = '수';
      break;
    case 4:
      DAY_NAME = '목';
      break;
    case 5:
      DAY_NAME = '금';
      break;
    case 6:
      DAY_NAME = '토';
      break;
  }
  const HOUR = dateInstance.getHours(); // hh
  const MINUTE = dateInstance.getMinutes(); // mm

  if (includeTime) {
    return `${YEAR}년 ${MONTH}월 ${DATE}일 ${DAY_NAME}요일 ${String(HOUR).padStart(2, '0')}:${String(MINUTE).padStart(2, '0')}`; // yyyy.mm.dd DAY hh:mm
  } else {
    return `${YEAR}년 ${MONTH}월 ${DATE}일 ${DAY_NAME}요일`; // yyyy.mm.dd DAY
  }
};

// https://www.npmjs.com/package/react-data-table-component/v/7.0.0-alpha-9#react-data-table-component
// https://react-data-table-component.netlify.app/?path=/story/examples-export-csv--export-csv

const RDSDataTable = (props: {
  data: any[];
  columns: any[];
  searchInput: JSX.Element | 'default';
  searchFilterKeyNames: string[];
  actionButton: JSX.Element | 'default';
  customStyle?: CSSObject;
  dataTableOptions?: {
    paginationPerPage: number;
    paginationRowsPerPageOptions: number[];
    [key :string]: any;
  };
  pagination?: boolean;
  selectableRows?: boolean;
  handlePreSelection?: RowState<any>;
  onSelectedRowsChange?: (selected: { allSelected: boolean; selectedCount: number; selectedRows: any[]; }) => void;
  onChangePaginationOption: (paginationOption: {
    rowsPerPage: number;
    page: number;
    offset: number;
    range: [number,number];
    searchText: string;
  }) => any;
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState<number | undefined>(props.dataTableOptions?.paginationPerPage);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentSort, setCurrentSort] = useState<any>();

  useEffect(()=> {
    if (props.dataTableOptions) {
      const rowsPerPage = currentRowsPerPage!;
      const page = currentPage;
      const offset = rowsPerPage * (page - 1);
      const range:[number,number] = [offset, offset + rowsPerPage]
      const paginationOption = {
        rowsPerPage,
        page,
        offset,
        range,
        searchText,
      }
      props.onChangePaginationOption(paginationOption)
    }
  }, [searchText, currentRowsPerPage, currentSort, currentPage])
  
  
  const searchFilteredData = props.searchFilterKeyNames
    ? props.data?.filter(
        (item) => {
          for (let i = 0; i < props.searchFilterKeyNames.length; i++) {
            if (Object.prototype.toString.call(item[props.searchFilterKeyNames[i]]) === '[object Date]') {
              if (parseKoreanTime(item[props.searchFilterKeyNames[i]], true).includes(searchText.normalize())) {
                return true
              }
            }
            if (String(item[props.searchFilterKeyNames[i]]) && String(item[props.searchFilterKeyNames[i]]).toLowerCase().includes(searchText.normalize().toLowerCase())) {
              return true
            }
          }
        }
      )
    : props.data;
  const subHeaderComponentMemo = React.useMemo(() => {
    const onChangeInputValue = (e: any) => {
      setSearchText(String(e.target.value).normalize());
    };
    return (
      <>
        {props.searchInput === 'default' ? (
          <input
            id="search"
            type="text"
            placeholder="Search"
            aria-label="Search Input"
            value={searchText}
            onChange={onChangeInputValue}
            css={{
              height: '32px',
              width: '80%',
              paddingLeft: '10px',
              background: '#FAFAFA',
              borderRadius: '4px',
              border: '1px solid #DDDDDD',
            }}
          />
        ) : (
          React.cloneElement(props.searchInput, {
            onChange: onChangeInputValue,
          })
        )}
      </>
    );
  }, [searchText]);

  return (
    <>
      <div
        className="RDSDataTable"
        css={{
          '& .rdt_TableHeader': {
            // header action 버튼 구역
            // backgroundColor: 'red',
            display: 'flex',
            justifyContent: 'flex-end',
            width: '50%',
            float: 'right',
            '@media (max-width: 500px)': {
              width: '100%',
            },
          },
          '& .TUEFO': {
            // header 검색input 영역
            // backgroundColor: 'yellow',
            display: 'flex',
            justifyContent: 'flex-start',
            width: '50%',
            '@media (max-width: 500px)': {
              justifyContent: 'flex-end',
              width: '100%',
            },
          },
          '& .rdt_TableHead': {
            // 알 수 없는 영역
            backgroundColor: 'blue',
          },
          '& .rdt_TableHeadRow': {
            // table column 이름 영역
          },
          '& .rdt_TableCell': {
            // table cell 영역
          },
          ...props.customStyle,
        }}
      >
        <DataTable
          columns={props.columns}
          data={searchFilteredData}
          subHeaderComponent={subHeaderComponentMemo}
          subHeader
          pagination={props.pagination}
          onChangeRowsPerPage={setCurrentRowsPerPage}
          onChangePage={setCurrentPage}
          onSort={setCurrentSort}
          {...props.dataTableOptions}
          selectableRows={props.selectableRows}
          selectableRowSelected={props.handlePreSelection}
          onSelectedRowsChange={props.onSelectedRowsChange}
        />
      </div>
    </>
  );
};

export default RDSDataTable;
