/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { PopupItem } from '../../../../types';

const columns = [
  {
    header: '팝업 만료 시각',
    prop: 'endDate',
    width: '120px'
  },
  {
    header: '사유',
    prop: 'typeLabel',
    width: '100px',
    marginLeft: '24px'
  },
  {
    header: '상태',
    prop: 'status',
    width: '100px',
    marginLeft: '24px'
  },
  {
    header: '게시 확정',
    prop: 'confirm',
    width: '100px',
    marginLeft: '24px'
  },
  {
    header: '미리보기',
    prop: 'preview',
    width: '60px',
    marginLeft: '24px'
  },
  {
    header: '수정',
    prop: 'edit',
    width: '60px',
    marginLeft: '24px'
  },
  {
    header: '삭제',
    prop: 'delete',
    width: '60px',
    marginLeft: '24px'
  },
];

type PostingPopupProps = {
  postingItems: PopupItem[];
  selectedItem: PopupItem | undefined;
  postConfirmed: Function;
  itemSelected: Function;
  setOpenDeleteModal: Function;
  setOpenPreviewModal: Function;
}

const PostingPopup = (props: PostingPopupProps) => {
  /*----------------------------------------
                   Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    document.querySelector('div.css-1taaqt9-ServiceDetail')?.setAttribute('style', 'height: 995px');
  }, []);

  useEffect(() => {
    /* 팝업 현황 아이템 갯수에 따른 전체 div height */
    const heightMapper = ['1125px', '1165px', '1205px', '1245px', '1285px'];
    const length = props.postingItems.length;

    if (props.postingItems && props.postingItems.length > 0) {
      document.querySelector('div.css-1taaqt9-ServiceDetail')?.setAttribute('style', `height: ${heightMapper[length - 1]}`);
    }
  }, [props.postingItems]);
  /*----------------------------------------
                 Business Logic
   ----------------------------------------*/
  const getStatusLabel = (status: string) => {
    return {waiting: '대기중', posting: '게시중'}[status];
  };
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  /**
   * 게시확정 버튼 클릭 이벤트 핸들러
   * state 를 on 으로 수정
   * @param item: PopupItem
   */
  const onConfirmButtonClicked = (item: PopupItem) => {
    const body = {
      projectId: 'test',
      endDate: item.endDate,
      type: item.typeValue,
      title: item.title,
      content: item.content,
      popupId: Number(item.popupId),
      state: 'on',
    };

    props.postConfirmed(body);
  };

  const onEditButtonClicked = (item: PopupItem) => {
    props.itemSelected(item);
  };

  const onDeleteButtonClicked = (item: PopupItem) => {
    props.itemSelected(item);
    props.setOpenDeleteModal(true);
  };

  const onPreviewHover = (item: PopupItem) => {
    props.setOpenPreviewModal(true);
    props.itemSelected(item);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      {props.postingItems && props.postingItems.length > 0 ? (
        <div
          css={{
            marginBottom: '24px',
          }}
        >
          <div
            css={{
              fontFamily: 'Noto Sans, sans-serif',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '32px',
              letterSpacing: '0.15px',
              color: '#FF0071',
            }}
          >
            팝업 현황
          </div>
          {/* columns area */}
          <div
            css={{
              marginTop: '15px',
              height: '22px',
            }}
          >
            {columns.map(column => {
              return (
                <div
                  css={{
                    display: 'inline-block',
                    width: column.width,
                    height: '22px',
                    fontFamily: 'Noto Sans, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '22px',
                    textAlign: 'center',
                    letterSpacing: '0.1px',
                    color: 'rgba(0, 0, 0, 0.87)',
                    marginLeft: column.marginLeft ? column.marginLeft : ''
                  }}
                  key={column.header}
                >
                  {column.header}
                </div>
              );
            })}
          </div>
          {/* rows area */}
          {props.postingItems.map((item: PopupItem) => {
            return (
              <div
                css={{
                  marginTop: '15px',
                  height: '24px',
                  background: item === props.selectedItem ? '#65A9FF1A' : ''
                }}
                key={item.popupId}
              >
                {columns.map(column => {
                  return (
                    <div
                      css={{
                        width: column.width,
                        display: 'inline-block',
                        marginLeft: column.marginLeft ? column.marginLeft : '',
                        fontFamily: 'Noto Sans, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '24px',
                        textAlign: 'center',
                        letterSpacing: '0.1px',
                        color: item[column.prop] === 'waiting' ? '#00AA92' : item[column.prop] === 'posting' ? '#FF0071' : 'rgba(0, 0, 0, 0.6)',
                        textDecorationLine: column.prop === 'typeLabel' || column.prop === 'status' ? 'underline' : '',
                      }}
                      key={column.header + item[column.prop]}
                    >
                      {column.prop === 'status' ?
                        getStatusLabel(item[column.prop]) :
                        column.prop === 'confirm' && item.state === 'off' ?
                          (
                            <Button
                              variant={'outlined'}
                              css={{
                                width: '100px',
                                height: '28px',
                                borderColor: '#00AA92',
                                borderRadius: '4px',
                                fontFamily: 'Noto Sans, sans-serif',
                                fontSize: '15px',
                                fontWeight: '500',
                                lineHeight: '26px',
                                letterSpacing: '0.46px',
                                fontStyle: 'normal',
                                color: '#00AA92',
                              }}
                              onClick={() => {
                                onConfirmButtonClicked(item)
                              }}
                            >
                              게시 확정
                            </Button>
                          ) :
                          column.prop === 'confirm' && item.state === 'on' ?
                            <>-</> :
                            column.prop === 'preview' ?
                              <VisibilityIcon
                                css={{cursor: 'pointer', color: '#00000061'}}
                                onMouseEnter={() => onPreviewHover(item)}
                              /> :
                              column.prop === 'edit' ?
                                <EditIcon
                                  css={{cursor: 'pointer', color: '#00000061'}}
                                  onClick={() => onEditButtonClicked(item)}
                                /> :
                                column.prop === 'delete' ?
                                  <DeleteIcon
                                    css={{cursor: 'pointer', color: '#00000061'}}
                                    onClick={() => onDeleteButtonClicked(item)}
                                  /> :
                                  item[column.prop]
                      }
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : ''}
    </>
  );
};

export default PostingPopup;