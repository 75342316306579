/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import ServiceHistory from '../../dashboard/ServiceHistory';
import { PostEmailItem } from '../../../../types';
import TextField from '@mui/material/TextField';
import InspectionTypeSelectBox from '../../dashboard/InspectionTypeSelectBox';
import ModalMaker from '../../../modals/ModalMaker';
import ReceiverModal from './ReceiverModal';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useLocation } from 'react-router-dom';
import Chip from '@mui/material/Chip';

const ServiceSendEmail = (props: any) => {
	/*----------------------------------------
                     Data
   ----------------------------------------*/
	const httpClient = props.httpClient;
	const location = useLocation();
	const projectId = location.search.split('=')[1];
	const [tags, setTags] = useState<{value: string, count: number, selected: boolean}[]>([]);
	const [historyItems, setHistoryItems] = useState<Array<PostEmailItem>>([]);
	const [inspectionType, setInspectionType] = useState<{value: string, label: string}>();
	const [emailTitle, setEmailTitle] = useState<string>('');
	const [emailContent, setEmailContent] = useState<string>('');
	const [openReceiverModal, setOpenReceiverModal] = useState<boolean>(false);
	const [receiveIdList, setReceiveIdList] = useState<number[]>([]);
	const [receiverInput, setReceiverInput] = useState<string>('');
	/*----------------------------------------
                   Life Cycle
   ----------------------------------------*/
	useEffect(() => {
		document.querySelector('div.css-1taaqt9-ServiceDetail')?.setAttribute('style', 'height: 1025px');
		initVars(undefined);
	}, []);

	useEffect(() => {
		if (receiverInput && receiverInput.length > 0) {
			if (receiverInput.substring(0, 1) === ',') {
				setReceiverInput(receiverInput.substring(2, receiverInput.length));
			}
		}
	}, [receiverInput, receiverInput.length]);
	/*----------------------------------------
                 Business Logic
   ----------------------------------------*/
	const initVars = async (searchWord: string | undefined) => {
		const data = await httpClient.getEmailList(projectId, searchWord);
		const tagData = await httpClient.getUserInfo(projectId);
		const tempTags = tagData.tags;
		tempTags.forEach((tag: {value: string, count: number, selected: boolean}) => {
			tag.selected = false;
		});

		setTags(tempTags);
		setEmailTitle('');
		setEmailContent('');
		setReceiverInput('');
		setInspectionType({value: 'emergency', label: '긴급 점검'});

		if (data.length > 16) {
			setHistoryItems(data.slice(0, 16));
		} else {
			setHistoryItems(data);
		}
	};
	/*----------------------------------------
                  Event Handler
   ----------------------------------------*/
	 const templateApply = async (item: PostEmailItem) => {
		 const data = await httpClient.getEmailDetail(projectId, Number(item.emailId));
		 setEmailTitle(data.title);
		 setEmailContent(data.content);
		 setInspectionType({value: data.typeValue, label: data.typeLabel});
	 };

	const historySearch = (searchWord: string) => {
		initVars(searchWord);
	};

	const sendEmail = () => {
		httpClient.postEmail(projectId, {
			type: inspectionType!.value,
			title: emailTitle,
			content: emailContent,
			userIdArr: receiveIdList
		}).then((result: number) => {
			if (result === 200) {
				initVars(undefined);
			}
		});
	};
	/*----------------------------------------
                Default Template
   ----------------------------------------*/
	return (
		<div css={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
			<ModalMaker state={openReceiverModal}>
				<ReceiverModal
					httpClient={httpClient}
					tags={tags}
					setTags={setTags}
					userIdList={receiveIdList}
					setUserIdList={setReceiveIdList}
					receiveIdList={receiveIdList}
					setReceiverInput={setReceiverInput}
					closeModal={setOpenReceiverModal}
				/>
			</ModalMaker>
			<div css={{ width: '846px', height: '100%', border: '1px solid #000000', padding: '15px' }}>
				<div
					css={{
							fontFamily: 'Noto Sans, sans-serif',
							fontSize: '20px',
							fontWeight: '500',
							lineHeight: '32px',
							letterSpacing: '0.15px',
							textAlign: 'left',
					  }}
				>
					전체 메일 발송
				</div>
				<div
					css={{
						marginTop: '24px',
					}}
				>
					<InspectionTypeSelectBox inspectionType={inspectionType} setInspectionType={setInspectionType} />
				</div>
				<div
					css={{
						marginTop: '24px',
					}}
				>
					<TextField
						sx={{
							width: '505px',
							height: '48px',
							fontFamily: 'Noto Sans, sans-serif',
							fontSize: '16px',
							fontWeight: '400',
							lineHeight: '48px',
							letterSpacing: '0.15px',
							textAlign: 'left',
						}}
						placeholder="제목을 입력해 주세요."
						size="medium"
						variant="standard"
						value={emailTitle}
						onChange={(e) => setEmailTitle(e.target.value)}
					/>
				</div>
				<div
					css={{
						marginTop: '24px',
						lineHeight: '48px',
					}}
				>
					<TextField
						sx={{
							width: '505px',
							height: '48px',
							fontFamily: 'Noto Sans, sans-serif',
							fontSize: '16px',
							fontWeight: '400',
							lineHeight: '48px',
							letterSpacing: '0.15px',
							textAlign: 'left',
						}}
						label="받는 고객"
						InputProps={{
							readOnly: true,
						}}
						variant="standard"
						value={receiverInput}
					/>
					<Button
						variant={'outlined'}
						css={{
							width: '120px',
							height: '36px',
							marginLeft: '24px',
							border: '1px solid rgba(0, 0, 0, 0.6)',
							color: 'rgba(0, 0, 0, 0.6)',
							borderRadius: '4px',
							fontFamily: 'Noto Sans, sans-serif',
							fontSize: '14px',
							fontWeight: '500',
							letterSpacing: '0.4px',
							fontStyle: 'normal',
							display: 'inline-block',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						onClick={() => {setOpenReceiverModal(true)}}
					>
						받는 고객
						<AddCircleOutlineIcon sx={{ width: '16px', height: '16px', marginLeft: '8px', marginBottom: '3px' }} />
					</Button>
				</div>
				<div
				 css={{
        	marginTop: '24px',
        }}>
					{
						tags && tags.length > 0 ? (
							tags.map((tag: {value: string, count: number, selected: boolean}, index: number) => {
								return tag.count > 0 ? <Chip
												key={`${tag.value}`}
												label={`${tag.value}(${tag.count})`}
												sx={{
													borderColor: tag.selected ? '' : '#0071FF',
													color: tag.selected ? '#FFFFFF' : '#0071FF',
													backgroundColor: tag.selected ? '#0071FF' : '',
													marginLeft: index !== 0 ? '10px' : '',
												}}
												variant="outlined"
												/> : '';
							})
						) : ''
					}
				</div>
				<textarea
					css={{
						border: '1px solid rgba(0, 0, 0, 0.6)',
						borderRadius: '10px',
						marginTop: '24px',
						padding: '10px',
						height: '401px',
						width: '100%',
						outline: 'none',
						fontFamily: 'Noto Sans, sans-serif',
						fontSize: '16px',
						fontWeight: '400',
						lineHeight: '24px',
						letterSpacing: '0.15px',
						color: '#000000',
						resize: 'none',
					}}
					placeholder={'내용을 입력해 주세요.'}
					value={emailContent}
					onChange={(e) => setEmailContent(e.target.value)}
				/>
				<div
					css={{
						marginTop: '24px',
						overflow: 'hidden',
					}}
				>
					<Button
						variant={'contained'}
						css={{
							float: 'right',
							width: '105px',
							height: '42px',
							borderRadius: '4px',
							backgroundColor: '#0071FF',
							fontFamily: 'Noto Sans, sans-serif',
							fontSize: '15px',
							fontWeight: '500',
							lineHeight: '26px',
							letterSpacing: '0.46px',
							fontStyle: 'normal',
							boxShadow: '0px 1px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.14)',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							color: '#FFFFFF',
						}}
						onClick={sendEmail}
					>
						메일 발송
					</Button>
				</div>
			</div>
			<div css={{ width: '374px', height: '100%', marginLeft: '10px', border: '1px solid #000000' }}>
				<ServiceHistory type={'email'} emailItems={historyItems} templateApply={templateApply} search={historySearch} />
			</div>
		</div>
	);
};

export default ServiceSendEmail;