import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

type SearchInputProps = {
  searchWord: string;
  setSearchWord: Function;
  handleKeyUp: Function;
  historySearch: Function;
}

const SearchInput = (props: SearchInputProps) => {
  return (
    <FormControl sx={{ margin: '0', marginLeft: '25px', padding: '0', width: '185px', height: '45px' }} variant="filled">
      <InputLabel htmlFor="filled-adornment-password">검색</InputLabel>
      <FilledInput
        id="filled-adornment-password"
        type={'text'}
        sx={{ background: '#FFFFFF', height: '45px' }}
        value={props.searchWord}
        onChange={(e) => props.setSearchWord(e.target.value)}
        onKeyUp={(e) => props.handleKeyUp(e)}
        endAdornment={
          <InputAdornment sx={{ height: '45px' }} position="end">
            <IconButton
              edge="end"
              onClick={() => props.historySearch()}
            >
              <SearchIcon sx={{ color: '#00000061' }} />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default SearchInput;